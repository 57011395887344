import auth from './modules/auth'
import showcase from '@/modules/showcase/router/showcase'
import category from '@/modules/category/router/category'
import showcaseProduct from '@/modules/catalog/showcase-product/router/showcase-product'
import stock from '@/modules/catalog/stock/router/stock'
import globalProduct from '@/modules/catalog/global-product/router/global-product'
import advantages from '@/modules/catalog/advantages/router/advantages'
import mainCharacteristic from '@/modules/catalog/main-characteristics/router/main-characteristic'
import showcaseCharacteristic from '@/modules/catalog/showcase-characteristics/router/showcase-characteristic'
import groupsCharacteristics from '@/modules/catalog/CharacteristicsGroups/router/characteristics-groups'
import tags from '@/modules/catalog/tags/router/tags'
import options from '@/modules/catalog/options/router/options'
import warehouses from '@/modules/catalog/warehouses/router/warehouses'
import filters from '@/modules/filters/router/filters'
import dictionaryColor from '@/modules/dictionary/colors/router/dictionaryColor'
import dictionaryLensColor from '@/modules/dictionary/lens-color/router/dictionaryLensColor'

export default [
    {
        path: "/",
        name: "default",
        meta: {
            title: "Dashboard",
            authRequired: true,
        },
        component: () => import("@/views/default.vue"),
    },
    ...auth,
    ...showcase,
    ...category,
    ...showcaseProduct,
    ...stock,
    ...globalProduct,
    ...advantages,
    ...mainCharacteristic,
    ...showcaseCharacteristic,
    ...groupsCharacteristics,
    ...tags,
    ...options,
    ...warehouses,
    ...filters,
    ...dictionaryColor,
    ...dictionaryLensColor,
];