export default [
    {
        path: "/main-characteristics",
        name: "main-characteristics",
        meta: {
            title: "Основные характеристики",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/main-characteristics/views/main-characteristics.vue"),
    },
    {
        path: "/main-characteristics/:id",
        name: "main-characteristics-view",
        meta: {
            title: "Глобальная характеристика",
            authRequired: true,
        },
        component: () => import("@/modules/catalog/main-characteristics/views/main-characteristics-view.vue"),
    }
];